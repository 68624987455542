export var LandingPath;
(function (LandingPath) {
    LandingPath["login"] = "login";
    LandingPath["signup"] = "signup";
    LandingPath["resetpassword"] = "resetpassword";
    LandingPath["confirmuser"] = "confirmuser";
})(LandingPath || (LandingPath = {}));
export var productType;
(function (productType) {
    productType["service"] = "service";
    productType["good"] = "good";
})(productType || (productType = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Pending"] = "Pending";
    UserStatus["Active"] = "Active";
    UserStatus["Inactive"] = "Inactive";
    UserStatus["Invited"] = "Invited";
})(UserStatus || (UserStatus = {}));
export var subscriptionCancellationReason;
(function (subscriptionCancellationReason) {
    subscriptionCancellationReason["CustomerService"] = "customer_service";
    subscriptionCancellationReason["LowQuality"] = "low_quality";
    subscriptionCancellationReason["MissingFeatures"] = "missing_features";
    subscriptionCancellationReason["SwitchedService"] = "switched_service";
    subscriptionCancellationReason["TooComplex"] = "too_complex";
    subscriptionCancellationReason["TooExpensive"] = "too_expensive";
    subscriptionCancellationReason["Unused"] = "unused";
    subscriptionCancellationReason["Other"] = "other";
})(subscriptionCancellationReason || (subscriptionCancellationReason = {}));
export var paymentCancellationReason;
(function (paymentCancellationReason) {
    paymentCancellationReason["abandoned"] = "abandoned";
    paymentCancellationReason["automatic"] = "automatic";
    paymentCancellationReason["duplicate"] = "duplicate";
    paymentCancellationReason["failed_invoice"] = "failed_invoice";
    paymentCancellationReason["fraudulent"] = "fraudulent";
    paymentCancellationReason["requested_by_customer"] = "requested_by_customer";
    paymentCancellationReason["void_invoice"] = "void_invoice";
})(paymentCancellationReason || (paymentCancellationReason = {}));
export var captureMethod;
(function (captureMethod) {
    captureMethod["automatic"] = "automatic";
    captureMethod["automatic_async"] = "automatic_async";
    captureMethod["manual"] = "manual";
})(captureMethod || (captureMethod = {}));
export var paymentStatus;
(function (paymentStatus) {
    paymentStatus["canceled"] = "canceled";
    paymentStatus["processing"] = "processing";
    paymentStatus["requiresAction"] = "requires_action";
    paymentStatus["requiresCapture"] = "requires_capture";
    paymentStatus["requiresConfirmation"] = "requires_confirmation";
    paymentStatus["requiresPaymentMethod"] = "requires_payment_method";
    paymentStatus["succeeded"] = "succeeded";
})(paymentStatus || (paymentStatus = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Employee"] = "Employee";
    UserRole["Client"] = "Client";
    UserRole["Vendor"] = "Vendor";
    UserRole["Admin"] = "Admin";
    UserRole["Staff"] = "Staff";
})(UserRole || (UserRole = {}));
export var UserPermission;
(function (UserPermission) {
    UserPermission["ReadOnly"] = "Read Only";
    UserPermission["Manage"] = "Manage";
})(UserPermission || (UserPermission = {}));
export var OrganizationPermission;
(function (OrganizationPermission) {
    OrganizationPermission["Visible"] = "Visible";
    OrganizationPermission["Hidden"] = "Hidden";
    OrganizationPermission["Blocked"] = "Blocked";
})(OrganizationPermission || (OrganizationPermission = {}));
export var OrganizationFeatures;
(function (OrganizationFeatures) {
    OrganizationFeatures["clients"] = "clients";
    OrganizationFeatures["tasks"] = "tasks";
    OrganizationFeatures["inventories"] = "inventories";
    OrganizationFeatures["invoices"] = "invoices";
    OrganizationFeatures["leads"] = "leads";
    OrganizationFeatures["deals"] = "deals";
    OrganizationFeatures["contacts"] = "contacts";
    OrganizationFeatures["engagements"] = "engagements";
    OrganizationFeatures["campaigns"] = "campaigns";
    OrganizationFeatures["products"] = "products";
    OrganizationFeatures["subscriptions"] = "subscriptions";
    OrganizationFeatures["payments"] = "payments";
    OrganizationFeatures["dataPools"] = "dataPools";
    OrganizationFeatures["lists"] = "lists";
    OrganizationFeatures["reports"] = "reports";
})(OrganizationFeatures || (OrganizationFeatures = {}));
export var DeviceNotification;
(function (DeviceNotification) {
    DeviceNotification["doNOtNotify"] = "Do Not Notify";
    DeviceNotification["security"] = "Security Updates";
    DeviceNotification["all"] = "All Important Updates";
})(DeviceNotification || (DeviceNotification = {}));
export var clientType;
(function (clientType) {
    clientType["Analyst"] = "Analyst";
    clientType["Bank"] = "Bank";
    clientType["Broker"] = "Broker";
    clientType["Competitor"] = "Competitor";
    clientType["Customer"] = "Customer";
    clientType["Company"] = "Company";
    clientType["Distributor"] = "Distributor";
    clientType["Individual"] = "Individual";
    clientType["Integrator"] = "Integrator";
    clientType["Investor"] = "Investor";
    clientType["Landlord"] = "Landlord";
    clientType["Partner"] = "Partner";
    clientType["Press"] = "Press";
    clientType["Prospect"] = "Prospect";
    clientType["Tenant"] = "Tenant";
    clientType["Supplier"] = "Supplier";
    clientType["Vendor"] = "Vendor";
    clientType["Other"] = "Other";
})(clientType || (clientType = {}));
export var taxExempt;
(function (taxExempt) {
    taxExempt["exempt"] = "exempt";
    taxExempt["none"] = "none";
    taxExempt["reverse"] = "reverse";
})(taxExempt || (taxExempt = {}));
export var automaticTaxStatus;
(function (automaticTaxStatus) {
    automaticTaxStatus["complete"] = "complete";
    automaticTaxStatus["failed"] = "failed";
    automaticTaxStatus["requires_location_inputs"] = "requires_location_inputs";
})(automaticTaxStatus || (automaticTaxStatus = {}));
export var billingReason;
(function (billingReason) {
    billingReason["manual"] = "manual";
    billingReason["subscription"] = "subscription";
    billingReason["subscription_create"] = "subscription_create";
    billingReason["subscription_cycle"] = "subscription_cycle";
    billingReason["subscription_threshold"] = "subscription_threshold";
    billingReason["subscription_update"] = "subscription_update";
    billingReason["upcoming"] = "upcoming";
})(billingReason || (billingReason = {}));
export var accountType;
(function (accountType) {
    accountType["account"] = "account";
    accountType["self"] = "self";
})(accountType || (accountType = {}));
export var DealType;
(function (DealType) {
    DealType["Existing"] = "Existing";
    DealType["New"] = "New";
    DealType["Other"] = "Other";
})(DealType || (DealType = {}));
export var SourceType;
(function (SourceType) {
    SourceType["Advertisement"] = "Advertisement";
    SourceType["ColdCall"] = "ColdCall";
    SourceType["EmployeeReferral"] = "EmployeeReferral";
    SourceType["ExternalReferral"] = "ExternalReferral";
    SourceType["OnlineStore"] = "OnlineStore";
    SourceType["Partner"] = "Partner";
    SourceType["PublicRelation"] = "PublicRelation";
    SourceType["Seminar"] = "Seminar";
    SourceType["TradeShow"] = "TradeShow";
    SourceType["WedResearch"] = "WedResearch";
    SourceType["Chat"] = "Chat";
    SourceType["Other"] = "Other";
})(SourceType || (SourceType = {}));
export var LeadType;
(function (LeadType) {
    LeadType["AttemtpedToContact"] = "AttemtpedToContact";
    LeadType["ContactInFuture"] = "ContactInFuture";
    LeadType["Contacted"] = "Contacted";
    LeadType["NotContacted"] = "NotContacted";
    LeadType["AwaitingResponse"] = "AwaitingResponse";
    LeadType["PreQualified"] = "PreQualified";
    LeadType["NotQualified"] = "NotQualified";
    LeadType["LostLead"] = "LostLead";
    LeadType["Other"] = "Other";
})(LeadType || (LeadType = {}));
export var CampaignType;
(function (CampaignType) {
    CampaignType["Email"] = "Email";
    CampaignType["Conference"] = "Conference";
    CampaignType["Webinar"] = "Webinar";
    CampaignType["TradeShow"] = "TradeShow";
    CampaignType["PublicRelations"] = "PublicRelations";
    CampaignType["Partners"] = "Partners";
    CampaignType["ReferrelProgram"] = "ReferrelProgram";
    CampaignType["Advertisement"] = "Advertisement";
    CampaignType["SocialMedia"] = "SocialMedia";
    CampaignType["BannerAds"] = "BannerAds";
    CampaignType["DirectMail"] = "DirectMail";
    CampaignType["Telemarketing"] = "Telemarketing";
    CampaignType["Others"] = "Others";
})(CampaignType || (CampaignType = {}));
export var customerType;
(function (customerType) {
    customerType["Paid"] = "Paid";
    customerType["Subscribed"] = "Subscribed";
    customerType["Priority"] = "Priority";
    customerType["Trial"] = "Trial";
    customerType["Inactive"] = "Inactive";
    customerType["Paused"] = "Paused";
})(customerType || (customerType = {}));
export var Priority;
(function (Priority) {
    Priority["High"] = "High";
    Priority["Medium"] = "Medium";
    Priority["Low"] = "Low";
})(Priority || (Priority = {}));
export var FeatureType;
(function (FeatureType) {
    FeatureType["DataPoolsManage"] = "Data Pools Manage";
    FeatureType["ContentsManage"] = "Contents Manage";
    FeatureType["SchemasManage"] = "Schemas Manage";
    FeatureType["StatisticsManage"] = "Statistics Manage";
    FeatureType["ListsManage"] = "Lists Manage";
    FeatureType["ReportsManage"] = "Reports Manage";
    FeatureType["UsageManager"] = "Export/Import Content";
    FeatureType["DownloadContent"] = "Download Content";
    FeatureType["Integrations"] = "Integrations";
})(FeatureType || (FeatureType = {}));
export var UsageType;
(function (UsageType) {
    UsageType["Export"] = "Export";
    UsageType["Import"] = "Import";
})(UsageType || (UsageType = {}));
export var ModelType;
(function (ModelType) {
    ModelType["clients"] = "clients";
    ModelType["tasks"] = "tasks";
    ModelType["inventories"] = "inventories";
    ModelType["invoices"] = "invoices";
    ModelType["leads"] = "leads";
    ModelType["deals"] = "deals";
    ModelType["contacts"] = "contacts";
    ModelType["engagements"] = "engagements";
    ModelType["campaigns"] = "campaigns";
    ModelType["products"] = "products";
    ModelType["subscriptions"] = "subscriptions";
    ModelType["payments"] = "payments";
})(ModelType || (ModelType = {}));
export var CategoryType;
(function (CategoryType) {
    CategoryType["content"] = "content";
    CategoryType["relations"] = "relations";
    CategoryType["financials"] = "financials";
})(CategoryType || (CategoryType = {}));
export var FilterType;
(function (FilterType) {
    FilterType["defaultView"] = "defaultView";
    FilterType["defaultSort"] = "defaultSort";
    FilterType["defaultRowsPerPage"] = "defaultRowsPerPage";
    FilterType["tableHeaders"] = "tableHeaders";
    FilterType["kanbanStatusField"] = "kanbanStatusField";
})(FilterType || (FilterType = {}));
export var UserSettingType;
(function (UserSettingType) {
    UserSettingType["theme"] = "theme";
})(UserSettingType || (UserSettingType = {}));
export var FormFieldType;
(function (FormFieldType) {
    FormFieldType["TextField"] = "TextField";
    FormFieldType["SingleSelect"] = "SingleSelect";
    FormFieldType["MultiSelect"] = "MultiSelect";
    FormFieldType["Number"] = "Number";
    FormFieldType["Date"] = "Date";
    FormFieldType["Switch"] = "Switch";
    FormFieldType["Checkbox"] = "Checkbox";
    FormFieldType["Time"] = "Time";
    FormFieldType["Currency"] = "Currency";
    FormFieldType["Percentage"] = "Percentage";
    FormFieldType["Color"] = "Color";
    FormFieldType["PhoneNumber"] = "PhoneNumber";
    FormFieldType["Link"] = "Link";
})(FormFieldType || (FormFieldType = {}));
export var FormFieldOrder;
(function (FormFieldOrder) {
    FormFieldOrder["asc"] = "asc";
    FormFieldOrder["desc"] = "desc";
})(FormFieldOrder || (FormFieldOrder = {}));
export var status;
(function (status) {
    status["Planning"] = "Planning";
    status["Ready"] = "Ready";
    status["Active"] = "Active";
    status["Blocked"] = "Blocked";
    status["Done"] = "Done";
    status["Failed"] = "Failed";
})(status || (status = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Grid"] = "Grid";
    ContentType["Table"] = "Table";
    ContentType["Kanban"] = "Kanban";
})(ContentType || (ContentType = {}));
export var CalculationType;
(function (CalculationType) {
    CalculationType["EqualsTo"] = "EqualsTo";
    CalculationType["Contains"] = "Contains";
    CalculationType["GreaterThan"] = "GreaterThan";
    CalculationType["LessThan"] = "LessThan";
})(CalculationType || (CalculationType = {}));
export var OperationType;
(function (OperationType) {
    OperationType["Count"] = "Count";
    OperationType["Sum"] = "Sum";
    OperationType["Mean"] = "Mean";
    OperationType["Median"] = "Median";
    OperationType["Maximum"] = "Maximum";
    OperationType["Minimum"] = "Minimum";
})(OperationType || (OperationType = {}));
export var FrequencyType;
(function (FrequencyType) {
    FrequencyType["Daily"] = "Daily";
    FrequencyType["Monthly"] = "Monthly";
    FrequencyType["Quarterly"] = "Quarterly";
    FrequencyType["Annually"] = "Annually";
})(FrequencyType || (FrequencyType = {}));
export var ChartType;
(function (ChartType) {
    ChartType["line"] = "line";
    ChartType["bar"] = "bar";
    ChartType["scatter"] = "scatter";
    ChartType["pie"] = "pie";
})(ChartType || (ChartType = {}));
export var Gender;
(function (Gender) {
    Gender["Male"] = "Male";
    Gender["Female"] = "Female";
    Gender["Others"] = "Others";
})(Gender || (Gender = {}));
export var AppMode;
(function (AppMode) {
    AppMode["light"] = "light";
    AppMode["dark"] = "dark";
})(AppMode || (AppMode = {}));
export var PlatformLanguage;
(function (PlatformLanguage) {
    PlatformLanguage["English"] = "English";
    PlatformLanguage["Spanish"] = "Spanish";
    PlatformLanguage["French"] = "French";
    PlatformLanguage["German"] = "German";
})(PlatformLanguage || (PlatformLanguage = {}));
export var collectionMethod;
(function (collectionMethod) {
    collectionMethod["payment_automatically"] = "payment_automatically";
    collectionMethod["send_invoice"] = "send_invoice";
})(collectionMethod || (collectionMethod = {}));
export var missingPaymentMethod;
(function (missingPaymentMethod) {
    missingPaymentMethod["cancel"] = "cancel";
    missingPaymentMethod["create_invoice"] = "create_invoice";
    missingPaymentMethod["pause"] = "pause";
})(missingPaymentMethod || (missingPaymentMethod = {}));
export var integrationType;
(function (integrationType) {
    integrationType["stripe"] = "stripe";
    integrationType["google_workspace"] = "google_workspace";
    integrationType["microsoft_teams"] = "microsoft_teams";
    integrationType["slack"] = "slack";
    integrationType["fixer_io"] = "fixer_io";
    integrationType["chat_gpt"] = "chat_gpt";
    integrationType["deepseek"] = "deepseek";
    integrationType["shippo"] = "shippo";
    integrationType["shopify"] = "shopify";
    integrationType["dealio"] = "dealio";
})(integrationType || (integrationType = {}));
export var currency;
(function (currency) {
    currency["usd"] = "usd";
    currency["aed"] = "aed";
    currency["afn"] = "afn";
    currency["all"] = "all";
    currency["amd"] = "amd";
    currency["ang"] = "ang";
    currency["aoa"] = "aoa";
    currency["ars"] = "ars";
    currency["aud"] = "aud";
    currency["awg"] = "awg";
    currency["azn"] = "azn";
    currency["bam"] = "bam";
    currency["bbd"] = "bbd";
    currency["bdt"] = "bdt";
    currency["bgn"] = "bgn";
    currency["bif"] = "bif";
    currency["bmd"] = "bmd";
    currency["bnd"] = "bnd";
    currency["bob"] = "bob";
    currency["brl"] = "brl";
    currency["bsd"] = "bsd";
    currency["bwp"] = "bwp";
    currency["byn"] = "byn";
    currency["bzd"] = "bzd";
    currency["cad"] = "cad";
    currency["cdf"] = "cdf";
    currency["chf"] = "chf";
    currency["clp"] = "clp";
    currency["cny"] = "cny";
    currency["cop"] = "cop";
    currency["crc"] = "crc";
    currency["cve"] = "cve";
    currency["czk"] = "czk";
    currency["djf"] = "djf";
    currency["dkk"] = "dkk";
    currency["dop"] = "dop";
    currency["dzd"] = "dzd";
    currency["egp"] = "egp";
    currency["etb"] = "etb";
    currency["eur"] = "eur";
    currency["fjd"] = "fjd";
    currency["fkp"] = "fkp";
    currency["gbp"] = "gbp";
    currency["gel"] = "gel";
    currency["gip"] = "gip";
    currency["gmd"] = "gmd";
    currency["gnf"] = "gnf";
    currency["gtq"] = "gtq";
    currency["gyd"] = "gyd";
    currency["hkd"] = "hkd";
    currency["hnl"] = "hnl";
    currency["htg"] = "htg";
    currency["huf"] = "huf";
    currency["idr"] = "idr";
    currency["ils"] = "ils";
    currency["inr"] = "inr";
    currency["isk"] = "isk";
    currency["jmd"] = "jmd";
    currency["jpy"] = "jpy";
    currency["kes"] = "kes";
    currency["kgs"] = "kgs";
    currency["khr"] = "khr";
    currency["kmf"] = "kmf";
    currency["krw"] = "krw";
    currency["kyd"] = "kyd";
    currency["kzt"] = "kzt";
    currency["lak"] = "lak";
    currency["lbp"] = "lbp";
    currency["lkr"] = "lkr";
    currency["lrd"] = "lrd";
    currency["lsl"] = "lsl";
    currency["mad"] = "mad";
    currency["mdl"] = "mdl";
    currency["mga"] = "mga";
    currency["mkd"] = "mkd";
    currency["mmk"] = "mmk";
    currency["mnt"] = "mnt";
    currency["mop"] = "mop";
    currency["mur"] = "mur";
    currency["mvr"] = "mvr";
    currency["mwk"] = "mwk";
    currency["mxn"] = "mxn";
    currency["myr"] = "myr";
    currency["mzn"] = "mzn";
    currency["nad"] = "nad";
    currency["ngn"] = "ngn";
    currency["nio"] = "nio";
    currency["nok"] = "nok";
    currency["npr"] = "npr";
    currency["nzd"] = "nzd";
    currency["pab"] = "pab";
    currency["pen"] = "pen";
    currency["pgk"] = "pgk";
    currency["php"] = "php";
    currency["pkr"] = "pkr";
    currency["pln"] = "pln";
    currency["pyg"] = "pyg";
    currency["qar"] = "qar";
    currency["ron"] = "ron";
    currency["rsd"] = "rsd";
    currency["rub"] = "rub";
    currency["rwf"] = "rwf";
    currency["sar"] = "sar";
    currency["sbd"] = "sbd";
    currency["scr"] = "scr";
    currency["sek"] = "sek";
    currency["sgd"] = "sgd";
    currency["shp"] = "shp";
    currency["sle"] = "sle";
    currency["sos"] = "sos";
    currency["srd"] = "srd";
    currency["std"] = "std";
    currency["szl"] = "szl";
    currency["thb"] = "thb";
    currency["tjs"] = "tjs";
    currency["top"] = "top";
    currency["try"] = "try";
    currency["ttd"] = "ttd";
    currency["twd"] = "twd";
    currency["tzs"] = "tzs";
    currency["uah"] = "uah";
    currency["ugx"] = "ugx";
    currency["uyu"] = "uyu";
    currency["uzs"] = "uzs";
    currency["vnd"] = "vnd";
    currency["vuv"] = "vuv";
    currency["wst"] = "wst";
    currency["xaf"] = "xaf";
    currency["xcd"] = "xcd";
    currency["xof"] = "xof";
    currency["xpf"] = "xpf";
    currency["yer"] = "yer";
    currency["zar"] = "zar";
    currency["zmw"] = "zmw";
})(currency || (currency = {}));
