const constants = {
    APP_LANGUAGES: {
        English: "English",
    },
    PAGE_LIMIT: "4",
    ROWS_PER_PAGE: ["4", "8", "16", "32", "48"],
    DEFAULT_FREQUENCY: "5",
    DEFAULT_ORDER: "asc",
    DEFAULT_SORT: "createdAt",
    DEFAULT_KANBAN_FIELD: "status",
    DEFAULT_PRIMARY_THEME: "#00a893",
    GQL_FIELD_TYPE: {
        LIST: "LIST",
        OBJECT: "OBJECT",
        Enum: "Enum",
        String: "String",
        Float: "Float",
        Int: "Int",
        Boolean: "Boolean",
    },
    STATIC_STORAGE_BUCKET_FOLDERS: {
        avatars: "avatars",
        exports: "exports",
        imports: "imports",
        logos: "logos",
        pools: "pools",
    },
    OPERATION_TYPE: {
        ADD: "Add",
        EDIT: "Edit",
    },
    CONTENTS_CUSTOM_TYPES: {
        accountOwners: "accountOwners",
        assignees: "assignees",
    },
    MODEL_CATEGORY_MAP: {
        leads: "relations",
        campaigns: "relations",
        tasks: "content",
        clients: "content",
        inventories: "content",
        deals: "relations",
        invoices: "content",
        contacts: "relations",
        engagements: "relations",
        products: "financials",
        subscriptions: "financials",
        payments: "financials",
        content: ["clients", "inventories", "invoices", "tasks"],
        relations: ["deals", "contacts", "engagements", "leads", "campaigns"],
        financials: ["products", "subscriptions", "payments"],
    },
    SETTING_TABS: {
        Personal: "Personal",
        Notifications: "Notifications",
        Organization: "Organization",
        Features: "Features",
        Team: "Team",
    },
    PARENT_ORGANIZATION_TYPE: {
        parent: "parent",
        sub: "sub",
    },
    POPULAR_EMAILS: [
        "gmail",
        "yahoo",
        "aol",
        "outlook",
        "aim",
        "yandex",
        "protonmail",
        "zoho",
        "gmx",
        "tutanota",
        "tutamail",
        "tuta",
        "keemail",
        "icloud",
    ],
    STATES: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ],
    COUNTRIES: ["USA"],
    CURRENCIES: {
        aed: "United Arab Emirates Dirham",
        afn: "Afghan Afghani",
        all: "Albanian Lek",
        amd: "Armenian Dram",
        ang: "Netherlands Antillean Guilder",
        aoa: "Angolan Kwanza",
        ars: "Argentine Peso",
        aud: "Australian Dollar",
        awg: "Aruban Florin",
        azn: "Azerbaijani Manat",
        bam: "Bosnia-Herzegovina Convertible Mark",
        bbd: "Barbadian Dollar",
        bdt: "Bangladeshi Taka",
        bgn: "Bulgarian Lev",
        bhd: "Bahraini Dinar",
        bif: "Burundian Franc",
        bmd: "Bermudan Dollar",
        bnd: "Brunei Dollar",
        bob: "Bolivian Boliviano",
        brl: "Brazilian Real",
        bsd: "Bahamian Dollar",
        btc: "Bitcoin",
        btn: "Bhutanese Ngultrum",
        bwp: "Botswanan Pula",
        byn: "New Belarusian Ruble",
        byr: "Belarusian Ruble",
        bzd: "Belize Dollar",
        cad: "Canadian Dollar",
        cdf: "Congolese Franc",
        chf: "Swiss Franc",
        clf: "Chilean Unit of Account (UF)",
        clp: "Chilean Peso",
        cny: "Chinese Yuan",
        cnh: "Chinese Yuan Offshore",
        cop: "Colombian Peso",
        crc: "Costa Rican Colón",
        cuc: "Cuban Convertible Peso",
        cup: "Cuban Peso",
        cve: "Cape Verdean Escudo",
        czk: "Czech Republic Koruna",
        djf: "Djiboutian Franc",
        dkk: "Danish Krone",
        dop: "Dominican Peso",
        dzd: "Algerian Dinar",
        egp: "Egyptian Pound",
        ern: "Eritrean Nakfa",
        etb: "Ethiopian Birr",
        eur: "Euro",
        fjd: "Fijian Dollar",
        fkp: "Falkland Islands Pound",
        gbp: "British Pound Sterling",
        gel: "Georgian Lari",
        ggp: "Guernsey Pound",
        ghs: "Ghanaian Cedi",
        gip: "Gibraltar Pound",
        gmd: "Gambian Dalasi",
        gnf: "Guinean Franc",
        gtq: "Guatemalan Quetzal",
        gyd: "Guyanaese Dollar",
        hkd: "Hong Kong Dollar",
        hnl: "Honduran Lempira",
        hrk: "Croatian Kuna",
        htg: "Haitian Gourde",
        huf: "Hungarian Forint",
        idr: "Indonesian Rupiah",
        ils: "Israeli New Sheqel",
        imp: "Manx pound",
        inr: "Indian Rupee",
        iqd: "Iraqi Dinar",
        irr: "Iranian Rial",
        isk: "Icelandic Króna",
        jep: "Jersey Pound",
        jmd: "Jamaican Dollar",
        jod: "Jordanian Dinar",
        jpy: "Japanese Yen",
        kes: "Kenyan Shilling",
        kgs: "Kyrgystani Som",
        khr: "Cambodian Riel",
        kmf: "Comorian Franc",
        kpw: "North Korean Won",
        krw: "South Korean Won",
        kwd: "Kuwaiti Dinar",
        kyd: "Cayman Islands Dollar",
        kzt: "Kazakhstani Tenge",
        lak: "Laotian Kip",
        lbp: "Lebanese Pound",
        lkr: "Sri Lankan Rupee",
        lrd: "Liberian Dollar",
        lsl: "Lesotho Loti",
        ltl: "Lithuanian Litas",
        lvl: "Latvian Lats",
        lyd: "Libyan Dinar",
        mad: "Moroccan Dirham",
        mdl: "Moldovan Leu",
        mga: "Malagasy Ariary",
        mkd: "Macedonian Denar",
        mmk: "Myanma Kyat",
        mnt: "Mongolian Tugrik",
        mop: "Macanese Pataca",
        mru: "Mauritanian Ouguiya",
        mur: "Mauritian Rupee",
        mvr: "Maldivian Rufiyaa",
        mwk: "Malawian Kwacha",
        mxn: "Mexican Peso",
        myr: "Malaysian Ringgit",
        mzn: "Mozambican Metical",
        nad: "Namibian Dollar",
        ngn: "Nigerian Naira",
        nio: "Nicaraguan Córdoba",
        nok: "Norwegian Krone",
        npr: "Nepalese Rupee",
        nzd: "New Zealand Dollar",
        omr: "Omani Rial",
        pab: "Panamanian Balboa",
        pen: "Peruvian Nuevo Sol",
        pgk: "Papua New Guinean Kina",
        php: "Philippine Peso",
        pkr: "Pakistani Rupee",
        pln: "Polish Zloty",
        pyg: "Paraguayan Guarani",
        qar: "Qatari Rial",
        ron: "Romanian Leu",
        rsd: "Serbian Dinar",
        rub: "Russian Ruble",
        rwf: "Rwandan Franc",
        sar: "Saudi Riyal",
        sbd: "Solomon Islands Dollar",
        scr: "Seychellois Rupee",
        sdg: "South Sudanese Pound",
        sek: "Swedish Krona",
        sgd: "Singapore Dollar",
        shp: "Saint Helena Pound",
        sle: "Sierra Leonean Leone",
        sll: "Sierra Leonean Leone",
        sos: "Somali Shilling",
        srd: "Surinamese Dollar",
        std: "São Tomé and Príncipe Dobra",
        svc: "Salvadoran Colón",
        syp: "Syrian Pound",
        szl: "Swazi Lilangeni",
        thb: "Thai Baht",
        tjs: "Tajikistani Somoni",
        tmt: "Turkmenistani Manat",
        tnd: "Tunisian Dinar",
        top: "Tongan Paʻanga",
        try: "Turkish Lira",
        ttd: "Trinidad and Tobago Dollar",
        twd: "New Taiwan Dollar",
        tzs: "Tanzanian Shilling",
        uah: "Ukrainian Hryvnia",
        ugx: "Ugandan Shilling",
        usd: "United States Dollar",
        uyu: "Uruguayan Peso",
        uzs: "Uzbekistan Som",
        vef: "Venezuelan Bolívar Fuerte",
        ves: "Sovereign Bolivar",
        vnd: "Vietnamese Dong",
        vuv: "Vanuatu Vatu",
        wst: "Samoan Tala",
        xaf: "CFA Franc BEAC",
        xag: "Silver (troy ounce)",
        xau: "Gold (troy ounce)",
        xcd: "East Caribbean Dollar",
        xdr: "Special Drawing Rights",
        xof: "CFA Franc BCEAO",
        xpf: "CFP Franc",
        yer: "Yemeni Rial",
        zar: "South African Rand",
        zmk: "Zambian Kwacha",
        zmw: "Zambian Kwacha",
        zwl: "Zimbabwean Dollar",
    },
    CURRENCY_SYMBOLS: {
        aed: "د.إ",
        afn: "؋",
        all: "L",
        amd: "֏",
        ang: "ƒ",
        aoa: "Kz",
        ars: "$",
        aud: "$",
        awg: "ƒ",
        azn: "₼",
        bam: "KM",
        bbd: "$",
        bdt: "৳",
        bgn: "лв",
        bhd: ".د.ب",
        bif: "FBu",
        bmd: "$",
        bnd: "$",
        bob: "Bs.",
        brl: "R$",
        bsd: "$",
        btc: "₿",
        btn: "Nu.",
        bwp: "P",
        byn: "Br",
        byr: "Br",
        bzd: "$",
        cad: "$",
        cdf: "FC",
        chf: "CHF",
        clf: "UF",
        clp: "$",
        cny: "¥",
        cnh: "¥",
        cop: "$",
        crc: "₡",
        cuc: "$",
        cup: "$",
        cve: "$",
        czk: "Kč",
        djf: "Fdj",
        dkk: "kr",
        dop: "$",
        dzd: "دج",
        egp: "£",
        ern: "Nfk",
        etb: "Br",
        eur: "€",
        fjd: "$",
        fkp: "£",
        gbp: "£",
        gel: "₾",
        ggp: "£",
        ghs: "₵",
        gip: "£",
        gmd: "D",
        gnf: "FG",
        gtq: "Q",
        gyd: "$",
        hkd: "$",
        hnl: "L",
        hrk: "kn",
        htg: "G",
        huf: "Ft",
        idr: "Rp",
        ils: "₪",
        imp: "£",
        inr: "₹",
        iqd: "ع.د",
        irr: "﷼",
        isk: "kr",
        jep: "£",
        jmd: "$",
        jod: "د.ا",
        jpy: "¥",
        kes: "KSh",
        kgs: "лв",
        khr: "៛",
        kmf: "CF",
        kpw: "₩",
        krw: "₩",
        kwd: "د.ك",
        kyd: "$",
        kzt: "₸",
        lak: "₭",
        lbp: "ل.ل",
        lkr: "₨",
        lrd: "$",
        lsl: "L",
        ltl: "Lt",
        lvl: "Ls",
        lyd: "ل.د",
        mad: "د.م.",
        mdl: "L",
        mga: "Ar",
        mkd: "ден",
        mmk: "K",
        mnt: "₮",
        mop: "MOP$",
        mru: "UM",
        mur: "₨",
        mvr: "ރ.",
        mwk: "MK",
        mxn: "$",
        myr: "RM",
        mzn: "MT",
        nad: "$",
        ngn: "₦",
        nio: "C$",
        nok: "kr",
        npr: "₨",
        nzd: "$",
        omr: "ر.ع.",
        pab: "B/.",
        pen: "S/",
        pgk: "K",
        php: "₱",
        pkr: "₨",
        pln: "zł",
        pyg: "₲",
        qar: "ر.ق",
        ron: "lei",
        rsd: "дин.",
        rub: "₽",
        rwf: "RF",
        sar: "ر.س",
        sbd: "$",
        scr: "₨",
        sdg: "£",
        sek: "kr",
        sgd: "$",
        shp: "£",
        sle: "Le",
        sll: "Le",
        sos: "S",
        srd: "$",
        std: "Db",
        svc: "₡",
        syp: "£",
        szl: "L",
        thb: "฿",
        tjs: "SM",
        tmt: "T",
        tnd: "د.ت",
        top: "T$",
        try: "₺",
        ttd: "$",
        twd: "NT$",
        tzs: "TSh",
        uah: "₴",
        ugx: "USh",
        usd: "$",
        uyu: "$U",
        uzs: "сум",
        vef: "Bs.F",
        ves: "Bs.",
        vnd: "₫",
        vuv: "Vt",
        wst: "T",
        xaf: "FCFA",
        xag: "Ag",
        xau: "Au",
        xcd: "$",
        xdr: "SDR",
        xof: "CFA",
        xpf: "₣",
        yer: "﷼",
        zar: "R",
        zmk: "ZK",
        zmw: "ZK",
        zwl: "Z$",
    },
    DEFAULT_INTEGRATIONS: [
        {
            name: "Finances",
            list: [
                {
                    name: "Stripe",
                    category: "Finances",
                    type: "stripe",
                    description: "Stripe's payments platform lets you accept credit cards, debit cards, and popular payment methods around the world—all with a single integration. Get access to advanced payments features like 3D Secure 2 authentication, card updates, automated retries, and more.",
                    title: "Integrate to stripe service and manage financial models",
                    logo: "img/integrations/stripe.png",
                    new: false,
                },
            ],
        },
        {
            name: "Workspaces",
            list: [
                {
                    name: "Google Workplace",
                    category: "Workspaces",
                    type: "google_workspace",
                    description: "Google Workspace (formerly G Suite) is a cloud-based productivity suite that provides tools for collaboration, communication, and business operations. Many companies use Google Workspace to improve efficiency, collaboration, and streamline workflows.",
                    title: "Integrate to Workspace and manage team members",
                    logo: "img/integrations/google_workspace.webp",
                    new: true,
                },
                {
                    name: "Microsoft Teams",
                    category: "Workspaces",
                    type: "microsoft_teams",
                    description: "Microsoft Teams is a collaboration and communication platform designed to facilitate teamwork in organizations, particularly those that rely on Microsoft’s ecosystem. It integrates chat, video conferencing, file storage, and app integration, making it a hub for team collaboration.",
                    title: "Integrate with your organization microsoft teams to manage employees",
                    logo: "img/integrations/microsoft_teams.png",
                    new: true,
                },
                {
                    name: "Slack",
                    category: "Workspaces",
                    type: "slack",
                    description: "Slack is a cloud-based messaging platform designed to enhance team communication and collaboration. It acts as a hub where teams can communicate in real-time, share files, and integrate with other software tools, streamlining workflows and reducing the need for long email threads.",
                    title: "Integrate with your organization slack and manage notifications",
                    logo: "img/integrations/slack.png",
                    new: true,
                },
            ],
        },
        {
            name: "AI",
            list: [
                {
                    name: "ChatGPT",
                    category: "AI",
                    type: "chat_gpt",
                    description: "ChatGPT is an artificial intelligence (AI) chatbot that uses natural language processing to generate human-like responses to user input.",
                    title: "Integrate chatGPT to your platform",
                    logo: "img/integrations/chatgpt.png",
                    new: true,
                },
                {
                    name: "DeepSeek",
                    category: "AI",
                    type: "deepseek",
                    description: "DeepSeek is a Chinese artificial intelligence company that develops open-source large language models (LLMs).",
                    title: "Integrate DeepSeek to your platform",
                    logo: "img/integrations/deepseek.png",
                    new: true,
                },
            ],
        },
        {
            name: "Commerce",
            list: [
                {
                    name: "Shopify",
                    category: "Commerce",
                    type: "shopify",
                    description: "Cloud-based commerce platform that allows businesses to sell products online and in-person.",
                    title: "Integrate with shopify to connect client usage to platform",
                    logo: "img/integrations/shopify.png",
                    new: true,
                },
                {
                    name: "Dealio",
                    category: "Commerce",
                    type: "dealio",
                    description: "A complete, Next-Gen Software-as-a-Service platform tailored for financial institutions.",
                    title: "Integrate with dealio to platform",
                    logo: "img/integrations/dealio.svg",
                    new: true,
                },
            ],
        },
        {
            name: "Logistics",
            list: [
                {
                    name: "Shippo",
                    category: "Logistics",
                    type: "shippo",
                    description: "Multi-carrier shipping platform that provides businesses with a simple way to integrate shipping functionality into their applications.",
                    title: "Integrate with shippo to generate labels and live shipping details",
                    logo: "img/integrations/shippo.png",
                    new: true,
                },
            ],
        },
        {
            name: "Miscellaneous",
            list: [
                {
                    name: "Fixer io",
                    category: "Miscellaneous",
                    type: "fixer_io",
                    description: "Fixer.io is an API service that provides real-time and historical exchange rate data for foreign currencies. It is primarily used by developers, businesses, and financial institutions to integrate currency conversion and exchange rate information into their applications, websites, or services.",
                    title: "Integration with fixer io to enable currency conversions across the platform",
                    logo: "img/integrations/fixer.png",
                    new: false,
                },
            ],
        },
    ],
};
export default constants;
