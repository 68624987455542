import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
const msalConfig = {
    auth: {
        clientId: process.env.MICROSOFT_CLIENT,
        authority: `https://login.microsoftonline.com/common`,
        redirectUri: process.env.MICROSOFT_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message) => {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
    },
};
export const msalInstance = new PublicClientApplication(msalConfig);
