import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache, concat, from } from "@apollo/client";
import { Amplify } from "aws-amplify";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { CssBaseline, ThemeProvider, createTheme } from "../components/mui";
import ColorModeContext from "./ColorModeContext";
import RouteConstants from "./RouteConstants";
import AppLogin from "../containers/AppLogin";
import SettingsContainer from "../containers/SettingsContainer";
import IntegrationsContainer from "../containers/IntegrationsContainer";
import ContentContainer from "../containers/ContentContainer";
import FinanceContainer from "../containers/FinanceContainer";
import RelationsContainer from "../containers/RelationsContainer";
import DashboardContainer from "../containers/DashboardContainer";
import ReportsContainer from "../containers/ReportsContainer";
import ListsContainer from "../containers/ListsContainer";
import ListContainer from "../containers/ListContainer";
import { AppMode } from "../utils/enums";
import { signOut } from "../utils/auth/graph_helper";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../utils/msalConfig";
const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: localStorage.getItem("token") || null,
            organization: localStorage.getItem("organizationId") || null,
            "Apollo-Require-Preflight": "true",
        },
    }));
    return forward(operation);
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
        console.log({ networkError });
    }
    if (graphQLErrors) {
        console.log({ graphQLErrors });
        const error = graphQLErrors[0]?.message;
        if (error === "Unauthorized: Invalid token provided.") {
            signOut();
        }
    }
});
const uploadLink = createUploadLink({ uri: `${process.env.GRAPH_URL}/graphql` });
const appLink = from([errorLink, uploadLink]);
const defaultOptions = {
    watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
    },
    query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
    },
    mutate: {
        errorPolicy: "all",
    },
};
const client = new ApolloClient({
    link: concat(authMiddleware, appLink),
    cache: new InMemoryCache(),
    defaultOptions,
});
Amplify.configure({
    Auth: {
        Cognito: {
            identityPoolId: process.env.POOL_ID,
            region: process.env.AWS_REGION,
            userPoolClientId: process.env.CLIENT_ID,
            userPoolId: process.env.USER_POOL,
        },
    },
});
const App = () => {
    msalInstance.initialize();
    const [mode, setMode] = React.useState(AppMode.light);
    const [primary, setPrimary] = React.useState({ 500: "#00a893", contrastText: "#fff" });
    const [secondary, setSecondary] = React.useState({ main: "#6D6D6D" });
    const colorMode = React.useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === AppMode.light ? AppMode.dark : AppMode.light));
        },
        defaultMode: ({ mode = AppMode.light }) => {
            setMode(mode);
        },
        togglePrimaryTheme: ({ color }) => {
            if (color) {
                setPrimary({ 500: color, contrastText: "#fff" });
            }
        },
    }), []);
    const theme = React.useMemo(() => createTheme({
        palette: {
            primary,
            secondary,
            mode,
        },
        breakpoints: {
            keys: ["xs", "sm", "md", "lg", "xl"],
            values: {
                xs: 0,
                sm: 740,
                md: 1027,
                lg: 1240,
                xl: 1560,
            },
        },
        typography: {
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Helvetica Neue",
                "Arial",
                "sans-serif",
                "Apple Color Emoji",
                "Segoe UI Emoji",
                "Segoe UI Symbol",
                "DM Mono",
                "DM Sans",
            ].join(","),
        },
    }), [mode, primary]);
    return (_jsx(MsalProvider, { instance: msalInstance, children: _jsx(ColorModeContext.Provider, { value: colorMode, children: _jsxs(ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(ApolloProvider, { client: client, children: _jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: RouteConstants.Login.path, render: (props) => _jsx(AppLogin, { ...props }) }, RouteConstants.Login.key), _jsx(Route, { exact: true, path: RouteConstants.Dashboard.path, render: (props) => _jsx(DashboardContainer, { ...props }) }, RouteConstants.Dashboard.key), _jsx(Route, { exact: true, path: RouteConstants.Content.path, render: (props) => _jsx(ContentContainer, { ...props }) }, RouteConstants.Content.key), _jsx(Route, { exact: true, path: RouteConstants.Relations.path, render: (props) => _jsx(RelationsContainer, { ...props }) }, RouteConstants.Relations.key), _jsx(Route, { exact: true, path: RouteConstants.Financials.path, render: (props) => _jsx(FinanceContainer, { ...props }) }, RouteConstants.Financials.key), _jsx(Route, { exact: true, path: RouteConstants.Lists.path, render: (props) => _jsx(ListsContainer, { ...props }) }, RouteConstants.Lists.key), _jsx(Route, { exact: true, path: RouteConstants.List.path, render: (props) => _jsx(ListContainer, { ...props }) }, RouteConstants.List.key), _jsx(Route, { exact: true, path: RouteConstants.Reports.path, render: (props) => _jsx(ReportsContainer, { ...props }) }, RouteConstants.Reports.key), _jsx(Route, { exact: true, path: RouteConstants.Integrations.path, render: (props) => _jsx(IntegrationsContainer, { ...props }) }, RouteConstants.Integrations.key), _jsx(Route, { exact: true, path: RouteConstants.Settings.path, render: (props) => _jsx(SettingsContainer, { ...props }) }, RouteConstants.Settings.key), _jsx(Redirect, { from: "/", to: RouteConstants.Login.path })] }) }) })] }) }) }));
};
export default App;
