import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Grid, FormField, FormDialog, Typography } from "../../mui";
import { FormFieldType, integrationType } from "../../../utils/enums";
import { ContentActions, getContent } from "../content";
const MANAGE_INTEGRATION = gql `
  mutation ManageIntegration($data: integrationInput!) {
    manageIntegration(data: $data) {
      id
    }
  }
`;
const StripeFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, name: "key", label: `Your ${data?.name} Access Key`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                } }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error
                    ? "This field is required"
                    : "All your secrets are store securely on cloud", name: "secret", label: `Your ${data?.name} Secret Key`, type: "password", size: "small", value: data.secret, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "importBridge", label: "Import all existing data from stripe", fieldType: FormFieldType.Switch, value: data.importBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "exportBridge", label: "Export all existing data from platform to stripe", fieldType: FormFieldType.Switch, value: data.exportBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const GoogleWorkspaceFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "key", label: `Your ${data?.name} Private Key/Credentials`, type: "password", size: "small", multiline: true, minRows: 3, maxRows: 6, InputProps: {
                    style: { WebkitTextSecurity: "disc" },
                }, value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "clientEmail", label: `Your Service Account Email`, type: "password", size: "small", value: data.clientEmail, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "customerId", label: `Your Customer ID`, type: "password", size: "small", value: data.customerId, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "importBridge", label: "Import all existing users from slack", fieldType: FormFieldType.Switch, value: data.importBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "exportBridge", label: "Export all existing users from platform to slack", fieldType: FormFieldType.Switch, value: data.exportBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const MicrosoftTeamsFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "key", label: `Your ${data?.name} Token`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "importBridge", label: "Import all existing users from teams", fieldType: FormFieldType.Switch, value: data.importBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "exportBridge", label: "Export all existing users from platform to teams", fieldType: FormFieldType.Switch, value: data.exportBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const SlackFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "key", label: `Your ${data?.name} Token`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "importBridge", label: "Import all existing users from slack", fieldType: FormFieldType.Switch, value: data.importBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "exportBridge", label: "Export all existing users from platform to slack", fieldType: FormFieldType.Switch, value: data.exportBridge, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const ShopifyFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "key", label: `Your ${data?.name} Access Key`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "secret", label: `Your ${data?.name} Secret`, type: "password", size: "small", value: data.secret, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const IntegrationFields = (React.FC) = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(FormField, { fieldType: FormFieldType.TextField, sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required." : "", name: "key", label: `Your ${data?.name} Access Key`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const ManagePoolIntegrationDialog = ({ open = true, integration = null, onDialogChange = () => null, setSnackBar = () => null, }) => {
    const [data, setData] = React.useState(integration);
    const [error, setError] = React.useState(false);
    const [manageIntegration] = useMutation(MANAGE_INTEGRATION, {
        refetchQueries: ["IntegrationsView"],
    });
    const onSaveFitlers = () => {
        if (data?.key === "" || data?.secret === "") {
            setError(true);
        }
        else {
            delete data.new;
            manageIntegration({
                variables: {
                    data,
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `Updated Organization Integration successfully!!`,
                    type: "success",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
            onDialogChange();
        }
    };
    const renderComponent = () => {
        switch (data.type) {
            case integrationType.stripe:
                return _jsx(StripeFields, { data: data, setData: setData, error: error, setError: setError });
            case integrationType.google_workspace:
                return _jsx(GoogleWorkspaceFields, { data: data, setData: setData, error: error, setError: setError });
            case integrationType.microsoft_teams:
                return _jsx(MicrosoftTeamsFields, { data: data, setData: setData, error: error, setError: setError });
            case integrationType.slack:
                return _jsx(SlackFields, { data: data, setData: setData, error: error, setError: setError });
            case integrationType.shopify:
                return _jsx(SlackFields, { data: data, setData: setData, error: error, setError: setError });
            default:
                return _jsx(IntegrationFields, { data: data, setData: setData, error: error, setError: setError });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(FormDialog, { open: open, setOpen: onDialogChange, title: data?.name, actions: _jsx(ContentActions, { onClose: onDialogChange, onSave: onSaveFitlers }), children: [_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: data?.title }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { color: "secondary", children: data?.description }) })] }), _jsx(Grid, { item: true, xs: 12, children: renderComponent() })] }) }));
};
export default ManagePoolIntegrationDialog;
