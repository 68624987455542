const SpanishContent = {
    companyName: "vermadev",
    companySlogan: "solución de gestión de datos sin código",
    submitLabel: "Entregar",
    startHereLabel: "Empieza aqui",
    loginHereLabel: "Entre aquí",
    logoutLabel: "Cerrar sesión",
    generalLabel: "General",
    backLabel: "Atrás",
    profileLabel: "Perfil",
    settingsLabel: "Integraciones",
    integrationsLabel: "Integrations",
    dashboardLabel: "Panel",
    dashboardWelcomeLabel: "Bienvenida!",
    reportsLabel: "Informes",
    reportLabel: "Informe",
    chartTypeLabel: "Tipo de gráfico",
    listsLabel: "Liza",
    contentLabel: "Contenido",
    relationsLabel: "Relaciones",
    continueLabel: "Continuar",
    userNameLabel: "Nombre de usuario",
    passwordLabel: "Contraseña",
    nameLabel: "Nombre",
    industryLabel: "Industria",
    websiteLabel: "Sitio web",
    linkedinLabel: "Linkedin Url",
    facebookLabel: "Facebook Url",
    twitterLabel: "Twitter Url",
    angelLabel: "Angel Url",
    crunchbaseLabel: "Crunchbase Url",
    primaryThemeLabel: "Tema principal",
    openSettingsLabel: "Configuración abierta",
    addModelLabel: "Agregar modelo",
    modelLabel: "Modelo",
    addPoolLabel: "Agregar grupo",
    dashboardNotificationsLabel: "Notificaciones de organización",
    notificationsLabel: "Notificaciones",
    emailNotificationsLabel: "Notificaciónes de Correo Electrónico",
    emailNotificationsMessage: "Las notificaciones por correo electrónico son personalizables, lo que permite a los usuarios elegir los tipos de notificaciones que desean recibir y establecer sus preferencias.",
    dailyReportsLabel: "Reportes diarios",
    dailyReportsMessage: "Obtenga un informe detallado de la actividad de la organización al final de su día. Utilizamos varios algoritmos y técnicas de aprendizaje automático para brindarle los detalles más importantes de la actividad de su equipo.",
    dealsClosingAlert: "Alertas de cierre de ofertas",
    dealsClosingMessage: "Sea el primero en recibir una notificación cuando se ejecuten o cierren ofertas",
    contentDeletionAlert: "Alertas de eliminación de contenido",
    contentDeletionMessage: "Mantener sus datos precisos es importante; reciba notificaciones cada vez que se eliminen activos o contenido de su organización",
    deviceNotificationsLabel: "Notificaciones de dispositivos",
    securityUpdatesMessage: "Reciba notificaciones solo cuando haya problemas de seguridad relacionados con su organización",
    allUpdatesMessage: "Obtenga todas las actualizaciones importantes y manténgase en contacto con cada actualización en vivo sobre su organización",
    newPasswordLabel: "Nueva contraseña",
    bottomFooterLabel: "Registro seguro",
    codeLabel: "Código",
    platformLanguageLabel: "Idioma de la plataforma",
    platformCurrencyLabel: "Moneda de la plataforma",
    preferredCurrencyLabel: "Moneda preferida",
    organizationNameLabel: "Nombre de la Organización",
    confirmPasswordLabel: "confirmar Contraseña",
    confirmationCodeLabel: "Código de confirmación",
    resetPassLabel: "Restablecer la contraseña",
    firstNameLabel: "Nombre de pila",
    lastNameLabel: "Apellido",
    jobTitleLabel: "Título profesional",
    emailLabel: "Email",
    defaultPassword: "Contraseña predeterminada",
    dobLabel: "Fecha de nacimiento",
    phoneNumberLabel: "Número de teléfono",
    contentCollageLabel: "Gestión de contenido",
    relationsCollageLabel: "Gestión de relaciones",
    forgotPassDescription: "Olvidaste tu contraseña?",
    codePassDescription: "Ya tienes un código?",
    resetPassDescription: "recuperarlo aquí",
    resetCodeDescription: "Terminar Regístrate aquí",
    backToLoginDescription: "Atrás para iniciar sesión",
    resendCodeLabel: "Reenviar código de confirmación",
    resendCodeDescription: "Gracias por registrarse en vermadev, escriba su correo electrónico para reenviar un código de confirmación",
    verifyCodeDescription: "Envia un correo electronico de verificación",
    confirmCodeDescription: "tengo mi codigo",
    signupSuccessDescription: "Gracias por registrarte en vermadev. Hemos enviado un código de confirmación a su correo electrónico. Copie y pegue el código aquí para continuar. Asegúrate de revisar tu carpeta de spam.",
    noDataDescription: "No se crean datos aquí",
    addSectionLabel: "Agregar sección",
    invoicePreviewLabel: "Vista previa de factura",
    inventoriesLabel: "Inventarios",
    exploreLabel: "Explorar",
    exploreMessage: "Al utilizar esta función, accederá a la organización deseada. ¿Le gustaría continuar todavía?",
    invoiceAmountLabel: "Cantidad total",
    reachOutMessageLabel: "Para cualquier pregunta, comuníquese con ",
    payToLabel: "Pagar a",
    viewLabel: "Vista",
    saveLabel: "Ahorrar",
    saveChangesLabel: "Guardar cambios",
    yesLabel: "Sí",
    editLabel: "Editar",
    closeLabel: "Cerca",
    cancelLabel: "Cancelar",
    okayLabel: "Bueno",
    errorLabel: "Error",
    downloadCSVLabel: "Descargar CSV",
    downloadPDFLabel: "Descargar PDF",
    newTeamMember: "Nueva miembro del equipo",
    teamMember: "Miembro del equipo",
    subscriptionLabel: "Suscripción",
    cancelSubscriptionLabel: "Cancelar suscripción",
    areYouSureLabel: "Estas segura!",
    cancelSubscriptionTitle: "¿Está seguro de que desea continuar con la cancelación de su suscripción?",
    cancelSubscriptionDescription: "Al continuar con esta acción, se cancelarán las cuentas de todos los miembros del equipo de esta organización. Le recomendamos que, si aún no lo ha hecho, suspenda su suscripción para poder seguir teniendo acceso a todos sus datos.",
    cancellationQuestion: "Por favor seleccione un motivo para su cancelación",
    thankYouMessage: "Gracias por elegir vermadev para sus necesidades de gestión de datos. La privacidad y protección de sus datos es nuestra máxima prioridad",
    pauseSubscription: "Pausar suscripción",
    currentSubscriptionStatus: "Estado de suscripción actual",
    pauseSubscriptionTitle: "¿Está seguro de que desea continuar con la pausa de su suscripción?",
    pauseSubscriptionDescription: "Si continúa, mantendremos sus datos lo más seguros posible. Puede exportar fácilmente cualquier conjunto de datos. Después de realizar esta acción, todos los miembros del equipo de su organización pasarán a tener la función de Visor, lo que permitirá un fácil acceso de solo lectura en toda la plataforma. Puede optar por reanudar su suscripción aquí en cualquier momento y se conservarán todas sus configuraciones de datos.",
    upgradeLabel: "Mejora",
    upgradeSubscription: "Suscripción de actualización",
    upgradeSubscriptionTitle: "En Verma Consulting, estamos orgullosos de ofrecer una experiencia de desarrollo dinámica completa que trabaja en estrecha colaboración con sus operaciones comerciales.",
    upgradeSubscriptionDescription: "La seguridad de los datos garantiza que la información confidencial, como la información de identificación personal (PII), los datos financieros, la propiedad intelectual y los secretos comerciales, permanezca confidencial y solo sea accesible para personas o entidades autorizadas. El acceso no autorizado a dicha información podría provocar robo de identidad, pérdidas financieras u otras formas de fraude.",
    currentStatusLabel: "Estado actual",
    periodEndsLabel: "Finaliza el período",
    nextBillingLabel: "Siguiente Fecha de facturación",
    choosePlanLabel: "Elige Plan",
    selectProductLabel: "seleccionar producto",
    selectModelLabel: "seleccionar modelo",
    selectRoleLabel: "seleccionar rol",
    selectFrequencyLabel: "seleccionar frecuencia",
    selectChartTypeLabel: "seleccione el tipo de gráfico",
    roleLabel: "Role",
    frequencyLabel: "Frecuencia",
    selectOperationLabel: "seleccionar operación",
    selectFieldLabel: "seleccionar campo",
    selectCalculationLabel: "seleccionar cálculo",
    calculationLabel: "Cálculo",
    calculationValueLabel: "Valor de cálculo",
    expiresOnLabel: "Expira el",
    paymentMethodLabel: "Método de pago",
    billingDetailsLabel: "Detalles de facturación",
    manageFiltersLabel: "Administrar filtros",
    dataModelNameLabel: "Nombre del modelo de datos",
    filtersLabel: "Filtros",
    schemaLabel: "Esquema",
    addSchemaLabel: "Agregar esquema",
    addOptionLabel: "Agregar opción",
    inRelationLabel: "En relación",
    manageStatTitle: "Administrar estadísticas para tu modelo",
    operationLabel: "Operación",
    typeLabel: "Tipo",
    searchLabel: "Buscar",
    refreshLabel: "Actualizar",
    addLabel: "Agregar",
    importLabel: "Importar",
    exportLabel: "Exportar",
    paymentLabel: "Pago",
    commentsLabel: "Comentarios",
    addressLabel: "La Dirección",
    assetsLabel: "Activos",
    deleteLabel: "Borrar",
    permissionsLabel: "Permisos",
    streetNumberLabel: "Número de calle",
    streetNameLabel: "Nombre de la calle",
    cityLabel: "Ciudad",
    postalCodeLabel: "Código Postal",
    stateLabel: "Estado",
    countryLabel: "País",
    uploadFileLabel: "Subir archivo",
    addStatLabel: "Agregar estadística",
    addParentOrganizationLabel: "Agregar organización principal",
    addSubOrganizationLabel: "Agregar suborganización",
    addOrganizationLabel: "Agregar organización",
    parentOrganizationMessage: "Por favor ingresa un nombre para continuar",
    useDynamicMessage: "Usar algoritmo dinámico",
    signUpPreMessage: "¿No tienes una cuenta?",
    signUpPostMessage: "Únete ahora",
    exportDescription: "La función de exportación le permite generar un archivo csv, enviado a través de un enlace descargable a su correo electrónico dados los filtros actuales para este contenido. Esto puede tardar varios minutos dependiendo del volumen de datos solicitados.",
    downloadCSVTitle: "Descargar archivo csv de muestra",
    downloadCSVDescription: "Cargue un archivo csv de muestra de datos para este modelo e importe entradas de forma masiva a sus grupos de datos. Seleccione un archivo después de presionar enviar",
    manageDataPoolProperties: "Administrar propiedades del grupo de datos",
    manageDataPoolLabel: "Administrar grupo de datos",
    addToListLabel: "Añadir a la listaGrupo de datos",
    selectListLabel: "Seleccione una lista para agregar grupos",
    schemaDialogTitle: "Aún no se ha creado ningún esquema adicional",
    schemaDialogSecondaryTitle: "Comience a agregar nuevos campos a su grupo de datos aquí cómodamente",
    organizationMessage: "Sigue manteniendo tus datos en nuestra red de confianza y elige uno de los siguientes planes. Sin embargo, recomendamos comunicarse con nuestro equipo para colaborar y desarrollar una solución específica para sus necesidades de análisis y gestión de datos.",
    newOrganizationMessage: "Creemos una organización para que usted termine de registrarse.",
    deleteHandlingMessage: "Una vez eliminado del grupo de datos, este contenido se eliminará de todas las instancias y no se podrá recuperar. ¿Aún te gustaría continuar?",
    errorHandlingMessage: "¡Ups! Parece que su última operación terminó con el error que se muestra a continuación. Le recomendamos que vuelva a iniciar sesión por favor. Este incidente ha sido reportado a nuestro equipo y la búsqueda de errores comenzará en breve.",
    signUpLabel: "Registrate aquí",
    upgradeMessage: "disfrutando vermadev? Por favor considere actualizar su cuenta aquí",
    contentContainer: "Administre los modelos de datos de contenido de su organización aquí.",
    relationsContainer: "Administre los modelos de datos de relaciones de su organización aquí.",
    listsContainer: "gestionar listas dinámicas/estáticas aquí",
    reportsContainer: "gestionar informes analíticos aquí",
    settingsContainer: "administre la configuración de usuario y organización aquí",
    integrationsContainer: "gestionar integraciones con aplicaciones externas aquí",
    addModelMessage: "agregue un nuevo modelo aquí",
    addStatMessage: "agregar una nueva estadística dinámica para este modelo de datos",
    searchMessage: "enviar para buscar por nombre",
    tableView: "ver grupos de datos en formato de tabla",
    gridView: "ver grupos de datos en formato de cuadrícula",
    kanbanView: "ver grupos de datos en formato kanban",
    modelMessage: "administrar el modelo de datos aquí",
    filtersMessage: "administrar los filtros del modelo de datos aquí",
    schemasMessage: "administre los campos del esquema del modelo de datos aquí",
    listsMessage: "agregue contenido a una lista existente aquí",
    importMessage: "cargue un documento para importar grupos de datos de forma masiva aquí",
    exportMessage: "Reciba un correo electrónico con un enlace para descargar un archivo de exportación CSV de este modelo de datos",
    refreshMessage: "actualizar el contenido a su estado natural aquí",
    addPoolMessage: "agrega nuevo contenido aquí",
    manageContentMessage: "gestionar contenido aquí",
    checkContentMessage: "haga clic para enviar actualizaciones",
    openContentMessage: "haga clic para navegar a este contenido",
    sortMessage: "haga clic para ordenar por esta columna",
    dragNDropMessage: "Arrastre y suelte la tarjeta para cambiar el estado",
    addListMessage: "agregue contenido a esta lista aquí",
    navigateBackToLists: "navegar de regreso a las listas",
    manageReportMessage: "gestionar informe aquí",
    addParentOrganization: "agregar una organización matriz a su organización actual",
    addSubOrganization: "agregar una nueva suborganización a su organización actual",
    currentOrganizationMessage: "organización actual preparada para explorar en esta sesión",
    permissionMessage: "alternar para limitar el acceso a esta función en todo el equipo",
    pdfDownloadMessage: "haga clic para descargar un archivo pdf de este contenido",
    csvDownloadMessage: "haga clic para descargar un archivo csv de este contenido",
    linkMessage: "haga clic para visitar el enlace",
    errorMessage: "Ocurrió un error!",
    createSuccessMessage: "creado exitosamente!",
    updatedSuccessMessage: "actualizado exitosamente!",
    removedSuccessMessage: "eliminado exitosamente!",
    exportMutationMessage: "Solicitud de exportación enviada correctamente!",
    importMutationMessage: "Solicitud de importación enviada correctamente!",
    labelText: "etiqueta",
    financialsLabel: "Finanzas",
    financialsCollageLabel: "Gestión Financiera",
    financialsContainer: "Administre los modelos de datos financieros de su organización aquí",
};
export default SpanishContent;
