// @ts-nocheck
import { Amplify } from "aws-amplify";
import { signUp as AmplifySignUp, confirmSignUp as AmplifyConfirmSignUp, resendSignUpCode as AmplifyResendSignUpCode, signIn as AmplifySignIn, resetPassword as AmplifyResetPassword, confirmResetPassword as AmplifyConfirmResetPassword, signOut as AmplifySignOut, fetchAuthSession, decodeJWT, } from "aws-amplify/auth";
import { PlatformLanguage, userRole, userStatus, currency, userSource } from "../enums";
import { CognitoIdentity } from "@aws-sdk/client-cognito-identity";
const cognitoidentity = new CognitoIdentity({
    region: process.env.AWS_REGION,
});
class CustomCredentialsProvider {
    // Example class member that holds the login information
    federatedLogin;
    // Custom method to load the federated login information
    loadFederatedLogin(login) {
        // You may also persist this by caching if needed
        this.federatedLogin = login;
    }
    async getCredentialsAndIdentityId(getCredentialsOptions) {
        try {
            // You can add in some validation to check if the token is available before proceeding
            // You can also refresh the token if it's expired before proceeding
            const getIdResult = await cognitoidentity.getId({
                // Get the identityPoolId from config
                IdentityPoolId: process.env.POOL_ID,
                Logins: { [this.federatedLogin.domain]: this.federatedLogin.token },
            });
            const cognitoCredentialsResult = await cognitoidentity.getCredentialsForIdentity({
                IdentityId: getIdResult.IdentityId,
                Logins: { [this.federatedLogin.domain]: this.federatedLogin.token },
            });
            const credentials = {
                credentials: {
                    accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId,
                    secretAccessKey: cognitoCredentialsResult.Credentials?.SecretKey,
                    sessionToken: cognitoCredentialsResult.Credentials?.SessionToken,
                    expiration: cognitoCredentialsResult.Credentials?.Expiration,
                },
                identityId: getIdResult.IdentityId,
            };
            return credentials;
        }
        catch (e) {
            console.log("Error getting credentials: ", e);
        }
    }
    // Implement this to clear any cached credentials and identityId. This can be called when signing out of the federation service.
    clearCredentialsAndIdentityId() { }
}
export async function newTeamMember(username = null, password = null) {
    try {
        const result = await AmplifySignUp({
            username,
            password,
            autoSignIn: {
                enabled: false,
            },
        });
        return {
            status: "success",
            result,
        };
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function signUp(firstName = "", lastName = "", phoneNumber = "", email = null, password = null, dateOfBirth = null, organizationName = "", isEmailConfirmed = false) {
    try {
        const { userId } = await AmplifySignUp({
            username: email,
            password,
            autoSignIn: {
                enabled: true,
            },
        });
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                cognitoUserId: userId,
                email,
                firstName,
                lastName,
                phoneNumber,
                dateOfBirth,
                organizationName,
                isEmailConfirmed,
                role: userRole.Admin,
            }),
        };
        const host = `${process.env.GRAPH_URL}/rest/register`;
        return fetch(host, requestOptions)
            .then((response) => response.json())
            .then(function (result) {
            return {
                status: "success",
                result,
            };
        })
            .catch(function (error) {
            return {
                status: "error",
                result: error,
            };
        });
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function confirmSignUp(email, confirmationCode) {
    try {
        let response = await AmplifyConfirmSignUp({ username: email, confirmationCode });
        if (response?.status === "error") {
            return response;
        }
        if (response?.isSignUpComplete) {
            const requestOptions = {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
                body: JSON.stringify({
                    email,
                }),
            };
            const host = `${process.env.GRAPH_URL}/rest/confirm`;
            return fetch(host, requestOptions)
                .then((response) => response.json())
                .then(function (result) {
                return {
                    status: "success",
                    result,
                };
            })
                .catch(function (error) {
                return {
                    status: "error",
                    result: error,
                };
            });
        }
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function resendSignUp(email) {
    try {
        const result = await AmplifyResendSignUpCode(email);
        return {
            status: "success",
            result,
        };
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function federatedMicrosoftSignIn(microsoftResponse) {
    try {
        localStorage.setItem("token", microsoftResponse?.accessToken || null);
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                token: microsoftResponse?.accessToken,
                email: microsoftResponse?.account?.username,
                firstName: microsoftResponse?.account?.name,
                status: userStatus.Active,
                role: userRole.Admin,
                source: userSource.microsoft,
            }),
        };
        const host = `${process.env.GRAPH_URL}/rest/federated/login`;
        return fetch(host, requestOptions)
            .then((response) => response.json())
            .then(function (response) {
            const { result } = response;
            localStorage.setItem("userId", result?.id || null);
            localStorage.setItem("organizationId", result?.organizationId || null);
            localStorage.setItem("language", result?.platformSettings?.language || PlatformLanguage.English);
            localStorage.setItem("currency", result?.currency || currency.usd);
            return response;
        })
            .catch((error) => ({
            error: true,
            result: error,
        }));
    }
    catch (error) {
        return {
            error: true,
            result: error,
        };
    }
}
export async function federatedGoogleSignIn(googleResponse) {
    try {
        const awsconfig = Amplify.getConfig();
        const customCredentialsProvider = new CustomCredentialsProvider();
        const cognitoTokenProvider = {
            async getTokens({ forceRefresh } = {}) {
                const accessTokenString = googleResponse.credential;
                const idTokenString = googleResponse.credential;
                return {
                    accessToken: decodeJWT(accessTokenString),
                    idToken: decodeJWT(idTokenString),
                };
            },
        };
        Amplify.configure({
            Auth: {
                Cognito: awsconfig.Auth.Cognito,
            },
        }, {
            Auth: {
                credentialsProvider: customCredentialsProvider,
                tokenProvider: cognitoTokenProvider,
            },
        });
        customCredentialsProvider.loadFederatedLogin({ domain: "accounts.google.com", token: googleResponse.credential });
        const fetchSessionResult = await fetchAuthSession();
        localStorage.setItem("token", fetchSessionResult?.credentials?.sessionToken || null);
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                token: fetchSessionResult?.credentials?.sessionToken,
                email: fetchSessionResult?.tokens?.accessToken?.payload?.email,
                firstName: fetchSessionResult?.tokens?.accessToken?.payload?.given_name,
                lastName: fetchSessionResult?.tokens?.accessToken?.payload?.family_name,
                logoUrl: fetchSessionResult?.tokens?.accessToken?.payload?.picture,
                isEmailConfirmed: fetchSessionResult?.tokens?.accessToken?.payload?.email_verified,
                status: userStatus.Active,
                role: userRole.Admin,
                source: userSource.google,
            }),
        };
        const host = `${process.env.GRAPH_URL}/rest/federated/login`;
        return fetch(host, requestOptions)
            .then((response) => response.json())
            .then(function (response) {
            const { result } = response;
            localStorage.setItem("userId", result?.id || null);
            localStorage.setItem("organizationId", result?.organizationId || null);
            localStorage.setItem("language", result?.platformSettings?.language || PlatformLanguage.English);
            localStorage.setItem("currency", result?.currency || currency.usd);
            return response;
        })
            .catch((error) => ({
            error: true,
            result: error,
        }));
    }
    catch (error) {
        return {
            error: true,
            result: error,
        };
    }
}
export async function addOrganization(name) {
    try {
        const userId = localStorage.getItem("userId") || null;
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                userId,
                name,
            }),
        };
        const host = `${process.env.GRAPH_URL}/rest/organization`;
        return fetch(host, requestOptions)
            .then((response) => response.json())
            .then(function (response) {
            const { result } = response;
            localStorage.setItem("userId", result?.user?.id || null);
            localStorage.setItem("organizationId", result?.organization?.id || null);
            return response;
        })
            .catch(function (error) {
            return error;
        });
    }
    catch (error) {
        return error;
    }
}
export async function check(email) {
    try {
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                email,
            }),
        };
        const host = `${process.env.GRAPH_URL}/rest/check`;
        return fetch(host, requestOptions).then((response) => response.json());
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function signIn(email, password) {
    try {
        const { isSignedIn } = await AmplifySignIn({ username: email, password });
        if (isSignedIn) {
            const { credentials } = await fetchAuthSession();
            localStorage.setItem("token", credentials?.sessionToken || null);
            const requestOptions = {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: credentials?.sessionToken,
                    email,
                }),
            };
            const host = `${process.env.GRAPH_URL}/rest/login`;
            return fetch(host, requestOptions)
                .then((response) => response.json())
                .then(function (response) {
                const { result } = response;
                localStorage.setItem("userId", result?.id || null);
                localStorage.setItem("organizationId", result?.organizationId || null);
                localStorage.setItem("language", result?.platformSettings?.language || PlatformLanguage.English);
                localStorage.setItem("currency", result?.currency || currency.usd);
                return {
                    status: "success",
                    result,
                };
            })
                .catch(function (error) {
                return {
                    status: "error",
                    result: error,
                };
            });
        }
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function forgotPassword(email) {
    try {
        const result = await AmplifyResetPassword(email);
        return {
            status: "success",
            result,
        };
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function forgotPasswordSubmit(email, code, newPassword) {
    try {
        const result = await AmplifyConfirmResetPassword(email, code, newPassword);
        return {
            status: "success",
            result,
        };
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
export async function signOut() {
    try {
        const userId = localStorage.getItem("userId") || null;
        const requestOptions = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                userId,
            }),
        };
        await AmplifySignOut({ global: true });
        window.localStorage.clear();
        const host = `${process.env.GRAPH_URL}/rest/logout`;
        return fetch(host, requestOptions)
            .then((response) => response.json())
            .then(function (result) {
            return {
                status: "success",
                result,
            };
        })
            .catch(function (error) {
            return {
                status: "error",
                result: error,
            };
        });
    }
    catch (error) {
        return {
            status: "error",
            result: error,
        };
    }
}
